import type { ErrorEvent, EventHint } from "@sentry/types";

import { batBeacon } from "../filters/batBeacon/batBeacon";
import { chunkLoadError } from "../filters/chunkLoadError/chunkLoadError";
import { crazyEggErrors } from "../filters/crazyEggErrors/crazyEggErrors";
import { objectMatchingId } from "../filters/objectMatchingId/objectMatchingId";
import { resizeObserverLoop } from "../filters/resizeObserverLoop/resizeObserverLoop";
import { errorsTurnedOffFilter } from "../filters/turnErrorsOff/turnErrorsOff";
import { webkitMessageHandlers } from "../filters/webkitMessageHandlers/webkitMessageHandlers";

const filters = [
  batBeacon,
  chunkLoadError,
  crazyEggErrors,
  objectMatchingId,
  resizeObserverLoop,
  errorsTurnedOffFilter,
  webkitMessageHandlers,
];

export const beforeSend = (event: ErrorEvent, hint: EventHint) => {
  for (const filter of filters) {
    const result = filter(event, hint);
    if (!result) return null;
  }

  return event;
};
